<template> 
    <div class="page-content  header-clear-small">
        <div id="preloader"><div class="spinner-border color-highlight" role="status"></div></div>
		<div class="card card-style" v-bind:style="{ backgroundImage: 'url(' + entity.background_url + ')' }">
            <div class="card-body my-3">
                <h5 class="badge bg-red-dark-sdw color-white px-1 py-1 font-12 mb-3">Annuaire</h5>
                <h1 class="color-white">
                    {{entity.name}}
                </h1>
            </div>
            <div class="card-overlay bg-black opacity-20"></div>
        </div>

        <div class="search-page">
            
            <div class="search-box search-header bg-theme card-style me-3 ms-3 mb-3">
                <i class="fa fa-search"></i>
                <input type="text" v-model="searchWord" class="border-0" placeholder="Recherche dans l'annuaire" data-search>
                <a href="#" @click.prevent="resetSearch" class="disabled"><i class="fa fa-times-circle color-red-dark-sdw"   style="font-size:18px"></i></a>
            </div>   
			<div class="card card-style">
            <div class="content mb-0" style="margin: 8px;">               
			<div class="input-style has-borders no-icon mb-2">
                    <select v-model="searchKey" @change="setSearchKey($event)" class="form-control" style="border:0; font-size:12px;">
                        <option value="0" disabled selected>Recherche par activité</option>
                        <option v-for="activity in activities" v-bind:key="activity.id" v-bind:value="activity.id">{{activity.name}}</option>
                    </select>
					<a v-if="searchKey" href="#" @click.prevent="resetSearch" style="position: absolute;top: 12px; right: 10px; z-index: 2;"><i class="fa fa-times-circle color-red-dark-sdw" style="font-size:18px"></i></a>
                </div>
				</div>
			</div>	
            <div class="search-results disabled-search-list card card-style shadow-l">
                <div class="content" v-if="entity.books && (entity.books.length > 0)">
                    <div v-for="book in entity.books"  v-bind:key="book.id" data-filter-item :data-filter-name="computedFilter(book)"  class="search-result-list">
					<Entry :book="book" v-if="book.status == 1"></Entry>	
                    </div>     
                    <div id="search-no-results" class="search-no-results disabled">
                        <h6 class="bold top-10">Aucun résultat trouvé...</h6>
                        <span class="under-heading font-11 opacity-70 color-theme">Veuillez choisir d'autres mots-clés.</span>
                    </div>
                </div>
            </div>
<!-- search directory from select-->
            <div class="card card-style shadow-l" id="search-directory" v-if="searchKey > 0">
                <div class="content" v-if="books && (books.length > 0)">
                    <div v-for="book in books"  v-bind:key="book.id" class="search-result-list">
						<Entry :book="book" v-if="book.status == 1"></Entry>
						<div class="divider mt-2 mb-2" v-if="book.status == 1"></div>
                    </div>     
                </div>
            </div>

<!-- /search directory -->
        </div>
		
		<div class="card card-style shadow-l" v-if="!searchKey && !searchWord">
			<div class="content" v-if="allbooks && (allbooks.length > 0)">
				<div v-for="book in allbooks"  v-bind:key="book.id">
						<Entry :book="book" v-if="book.status == 1"></Entry>
						<div class="divider mt-2 mb-2" v-if="book.status == 1"></div>
				</div>     
			</div>
		</div>	
		<!--footer-->		
	</div>
    <!-- End of Page Content--> 
</template>

<script>
import Entry from '@/components/Entry.vue'
import AppConstants from '@/config'
export default {
  name: 'Book',
  data() {
    return{
	loading: false,
	entity: {},
	activities: [],
	books:[],
	allbooks:[],
	searchKey:0,
	searchWord:''
	}
  },
  components: {
    Entry
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    cat: {
      type: [String, Number],
      default: 0
    },	
    isloggedin: {
      type: Number,
      default: 5
    }	
  },  
  created() {
     //this.getEntity()
  },  
  mounted() {
	//
	this.findCat()
	this.getEntity()
  }, 
	unmounted() {
    // if (this.entity == {}) this.getEntity()
  },  
  methods: {
	findCat() {
		if (this.cat) {
			this.searchKey = this.cat
		}
	
	},
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},  
	computedFilter(book) {
		if (!book.status) return '';
		var sep = ' ';
		var ret = 'tous'+ sep;
		var firstname = '';
		if (book.firstname) firstname = book.firstname+ sep;
		
		var name = '';
		if (book.name) name = book.name+ sep;
		
		var manager_name = '';
		if (book.manager_name) manager_name = book.manager_name+ sep;

		var manager_firstname = '';
		if (book.manager_firstname) manager_firstname = book.manager_firstname+ sep;		
		
		var activities = '';
		var i, s;
		if (book.activities) {
			s = book.activities.length
			for (i=0; i< s; i++) {
				
				activities = activities + book.activities[i]['name'] + sep;
			}
		}
		var tags = '';
		if (book.tags) {
			s = book.tags.length
			for (i=0; i< s; i++) {
				tags = tags + book.tags[i]['name'] + sep;
			}
		}		
		ret = ret + firstname + name + manager_name + manager_firstname + activities + tags;
		return ret.toLowerCase();
	
	},
	getEntity() {
				this.loading = true
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"anonymous-books"				
				
                if(this.id != 0) {
                    var apiData = { 
					"entity": this.id,
					"activity": this.searchKey,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
						this.entity = response.data.data
						this.activities = response.data.activities
						this.allbooks = this.entity.books
						if (this.searchKey > 0) {
							this.books = response.data.books
						} else {
							this.books = []
						}
						this.loading = false
						
					}
				})
				.catch(error => {
				console.log(error.message);
				//this.searchKey = 0
				})	
				} 
	},	
	setSearchKey(event) {
		this.searchKey = event.target.value
		this.getEntity()
	},
	resetSearch(){
	this.searchKey = 0
	this.searchWord = ''
	}
  }
}


</script>
<style>
.search-page .search-result-list {
    min-height: 80px;
    margin-bottom: 0px;
}
.icon-xls {width:30px;}
</style>
