<template>
<div>
						<h6 v-if="book.type == 1" class="pl-1 text-uppercase">{{book.firstname}} {{book.name}}</h6>
						
						<h6 v-else class="pl-1 text-uppercase">{{book.name}} 
							<span class="small text-muted pl-1" v-if="book.manager_firstname">{{book.manager_firstname}}</span>
							<span class="small text-muted pl-1" v-if="book.manager_name">{{book.manager_name}}</span>
						</h6>
						
                        <p class="pl-1 mb-0 pr-1">
                            <span v-if="book.address"><strong>Adresse</strong> : {{book.address}} {{book.zip}} {{book.city}}
							</span>
							<span v-if="book.activities.length > 0" class="text-muted d-block">
								<strong>Activités</strong> : <span v-for="activity in book.activities" :key="activity.id">
								{{activity.name}}
								</span>
							
							</span>
							<span v-if="book.website" class="text-muted d-block"><strong>Site web</strong> : <a :href="book.website" target="_blank">{{book.website}}</a></span>
							<span v-if="book.description" class="text-muted d-block">{{book.description}}</span>
											
						</p>
                       <span class="/*text-center mb-3*/ float-right mb-0 mt-2">
						
						<a v-if="book.facebook" :href="book.facebook" class="icon icon-xls rounded-sm shadow-l me-1 bg-facebook mr-2"><i class="fab fa-facebook-f font-12"></i></a>
						
						<a v-if="book.twitter" :href="book.twitter" class="icon icon-xls rounded-sm shadow-l me-1 bg-twitter mr-2"><i class="fab fa-twitter font-12"></i></a>
						
						<a v-if="book.linkedin" :href="book.linkedin" class="icon icon-xls rounded-sm shadow-l me-1 bg-linkedin mr-2"><i class="fab fa-linkedin font-12"></i></a>
						
						<a v-if="book.instagram" :href="book.instagram" class="icon icon-xls rounded-sm shadow-l me-1 bg-instagram mr-2"><i class="fab fa-instagram font-12"></i></a>
						
						<a v-if="book.phone" :href="'tel://'+book.phone" class="icon icon-xls rounded-sm shadow-l me-1 bg-phone mr-2"><i class="fa fa-phone font-12"></i></a>
						</span>
						<div style="width:100%; display:block; clear:both"></div>
</div>						
</template>
<script>
export default {
  name: 'Entry',
    props: {
    book: {
      type: Object,
      default: null
    }	
  }
}
</script>